import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Team from '@sections/Team';
import Termine from '@sections/Termine';
import Footer from '@sections/Footer';


import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';

// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'de';

const IndexPage = () => (
  <Layout>
    {/* <Navbar /> */}
    <Header />
    {/* <About /> */}
    {/* <Team /> */}
    {/* <Termine /> */}
    {/* <Footer /> */}
  </Layout>
);

export default IndexPage;
