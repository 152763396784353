import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import { Container } from '@components/global';
import {
  Nav,
  NavItem,
  // Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';

const NAV_ITEMS = ['Leistungen', 'Team', 'Workshops'];

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = item => (
    <AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu} offset='100'>
      {item}
    </AnchorLink>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-72}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  render () {
    console.log(`These are the props: ${this.props}`);
    const { mobileMenuOpen } = this.state;
      // const { data } = this.props; // add more props here as needed

      return (
        <Nav {...this.props}>
          <StyledContainer>
            <AnchorLink href="#">
              <Img fluid={this.props.data.persuadioLogo.childImageSharp.fixed} style={{ width: '200px', height: '104px', marginLeft: '30px' }}></Img>
            </AnchorLink>
            <Mobile>
              <button onClick={this.toggleMobileMenu} style={{ color: 'black' }}>
                <MenuIcon />
              </button>
            </Mobile>

            <Mobile hide>{this.getNavList({})}</Mobile>
          </StyledContainer>
          <Mobile>
            {mobileMenuOpen && (
              <MobileMenu>
                <Container>{this.getNavList({ mobile: true })}</Container>
              </MobileMenu>
            )}
          </Mobile>
        </Nav>
      );
      }
    }

export default props => (
  <StaticQuery
    query={graphql`
      query {
        persuadioLogo: file(
          sourceInstanceName: { eq: "logos" }
          name: { eq: "Logo_Persuadio_s" }
        ) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={( data ) => {
        return (<Navbar data={data} {...props} />)
      }
    }
  />)
