import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import BackgroundDiv from '@common/BackgroundDiv';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
      persuadioLogo: file(
          sourceInstanceName: { eq: "logos" }
          name: { eq: "Logo_Persuadio_s" }
        ) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => {
      return(
      <HeaderWrapper>
        <Grid>
          {/* <Art>
            <img src={ data.art_mic.childImageSharp.fluid.src }/>
          </Art> */}
          <BgDiv>
          </BgDiv>
          <Img fixed={data.persuadioLogo.childImageSharp.fixed}
          objectFit='contain'
          style={{
            position: 'absolute',
            top: '10%',
            left: '50%',
            marginLeft: '-100px',
            width: '200px',
            height: '104px',
            backgroundColor: 'rgba(255,255,255,0.5)',
            }}
          imgStyle={{ padding: '10px 10px', objectFit: 'contain'}}
            ></Img>
        </Grid>
      </HeaderWrapper>
    )
    }}
  />
);

const BgDiv = styled(BackgroundDiv)`
  height: 100%;
  width: 100%;
  position: absolute;
`;

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  height: 100vh;

  @media (max-width: ${props => props.theme.screen.md}) {
  }
`;

const Art = styled.figure`
  align-self: center;
  width: 50%;
  margin: 0px;
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  > img {
    width: 100%;
    margin-bottom: -4.5%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    height: unset;
  }
`;

const Grid = styled.div`
  // padding-top: 100px;
  height: 100%;
  position: relative;
  // display: flex;
  // flex-direction: row;
  // justify-content: end;
  overflow: hidden;

  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
    > ${Art} {
      // order: 2;
    }
  }
`;


const Text = styled.div`
  // justify-self: center;
  //align-self: center;
  position: absolute;
  top: 50%;
  right:10%;
  padding: 50px;
  background-color: rgba(255,255,255,0.8);

  @media (max-width: ${props => props.theme.screen.md}) {
    top: unset;
    right: 0%;
    bottom: 5%;
  }
`;



// const StyledExternalLink = styled(ExternalLink)`
//   color: inherit;
//   text-decoration: none;

//   &:hover {
//     color: ${props => props.theme.color.black.regular};
//   }
// `;

export default Header;

