import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

const BackgroundDiv = ({ className }) => (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(
            sourceInstanceName: { eq: "art" }
            name: { eq: "pexels-skitterphoto-675960" }
            ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920, grayscale: true) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // Set ImageData.
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <BackgroundImage
            Tag="div"
            className={className}
            fluid={imageData}
            backgroundColor={`#040e18`}
          >
            <p></p>
          </BackgroundImage>
        )
      }}
    />
  )

  export default BackgroundDiv;