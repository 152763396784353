import React from 'react';
import Collapsible from 'react-collapsible';
import Moment from 'react-moment';
import styled from 'gatsby';

import { Wrapper } from './style';

const WorkshopItem = ({ title, date, venue, children }) => (
  <Wrapper>
    <Collapsible
      className="workshop"
      openedClassName="workshop active"
      triggerClassName="workshop-title"
      triggerOpenedClassName="workshop-title active"
      triggerTagName="button"
      contentInnerClassName="workshop-content"
      trigger={<TriggerDiv title={title} date={date} venue={venue}></TriggerDiv>}
      transitionTime={300}
      easing="ease-out"
      >
      {children}
    </Collapsible>
  </Wrapper>
);

class TriggerDiv extends React.Component {
  render() {
    console.log(`${this.props.title} ${this.props.venue} ${this.props.date}`)

    const {title, venue, date } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span><nobr><Moment format="dddd, DD.M.YYYY">{date}</Moment> um <Moment format="HH:mm">{date}</Moment></nobr></span>
          <br />
          <span style={{ fontSize: '0.8em' }}>{venue}</span>
        </div>
        <div style={{textAlign: 'right' }}><h3>{title}</h3></div>
      </div>
    )
  }
};

export default WorkshopItem;
