import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_measuretape: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "pexels-cottonbro-4614246" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760, grayscale: true) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760, grayscale: true) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_typewriter: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "pexels-skitterphoto-675960" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760, grayscale: true) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="leistungen">
        <Container>
          <Grid>
            <div>
              <h2>Reden nach Maß</h2>
              <p>
              Wir schreiben Ihnen die Rede, die Sie schon immer halten wollten – individuell zugeschnitten auf Sie und Ihren Erfolg.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_measuretape.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_typewriter.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Workshop Redenschreiben</h2>
              <p>
              Redenschreiben ist wie Radfahren: Man lernt es und vergisst es nie mehr. Willkommen zu unserem Workshop RedenSchreiben – kompakt an zwei Tagen, in einer kleinen Gruppe und mit den Tipps und Techniken der Profis.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>Rednercoaching</h2>
              <p>
              Sie müssen eine Rede halten und es soll wirklich gut werden? Wir arbeiten mit Ihnen an Ihrem Text, Ihrem Auftritt oder gerne auch beidem.
                <br />
                <br />
                Unser Rednercoaching mit Video-Feedback, gerne auch online – und Sie sind perfekt vorbereitet!
              </p>
            </div>
            <Art>
              <Img fluid={data.art_measuretape.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_typewriter.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Storyfiling</h2>
              <p>
              Die Storyprofiler von PERSUADIO helfen Ihnen dabei: Sie arbeiten den Identitätskern Ihres Unternehmens heraus, legen Ihre hidden diamonds frei und schreiben eine Story mit Herzblut, Held und Höhepunkt dazu. Sie werden staunen, wer Sie sind!
              </p>
            </div>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
